<template>
  <el-dialog :title="$t('event.playAudio')" :close-on-click-modal="false" :visible.sync="dialogVisible" width="30%"
    top="5vh" @close="onDialogClose">
    <div v-loading="contentLoading" style="text-align: center;">
      <audio ref="audioPlayer" :src="audioUrl" controls></audio>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      submitLoading: false,
      saveDisabled: false,
      dialogVisible: false,
      contentLoading: false,
      audioUrl: "",
      callRecordId: 0,

    };
  },
  computed: {},
  mounted() {

  },
  methods: {
    open(id) {
      this.dialogVisible = true;
      this.callRecordId = id;
      if (id > 0) {
        this.getData();
      }
    },
    getData() {
      this.contentLoading = true;
      this.saveDisabled = true;

      this.$api.exportFile("alarmRecords/getAudio/" + this.callRecordId, null, 60).then(res => {
        console.log("data=" + res.data);
        if (res.data != null) {
          this.audioUrl = URL.createObjectURL(new Blob([res.data]));
          this.contentLoading = false;
          this.saveDisabled = false;
        }
      }).catch((error) => {
        this.$message.error(error);
      });

    },
    onDialogClose() {

    },
  },
};
</script>

<style lang="scss" scoped></style>
